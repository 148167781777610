#container-question-jampp .dsd-radio-group-wrapper {
  margin-bottom: 0px;
}

#container-question-jampp .dsd-radio-host .dsd-radio-wrapper {
  padding-top: 0 !important;
  min-height: 0 !important;
}

#container-question-jampp div.dsd-radio-group-list {
  flex-direction: column;
}
  
#container-question-jampp #liste-puces {
  margin-bottom: 0px;
  margin-left: 12px;
  font-weight: normal;
} 
  
#container-question-jampp span.dsd-form-label-star {
  position: relative;
  left: -10px;
  margin-right: -10px;
}
  
#container-question-jampp div.dsd-radio-group-wrapper.dsd-is-error {
  margin-bottom: 0px;
}
  
#container-question-jampp .liste-erreur {
  color: #ca241a;
}
  
#container-question-jampp .dsd-is-error ul#liste-puces {
  padding-left: 9px;
}
  
#container-question-jampp span[slot="legend"] {
  color: #383838;
  position: relative;
  top: 3px;
  font-size: 1rem;
  line-height: 24px;
}
  
#container-question-jampp #groupe-bouton-radio,
.dsd-is-error .dsd-radio-group-list,
.dsd-form-error {
  margin-left: -10px;
}
  
#container-question-jampp dsd-radio:first-child .dsd-is-error span.dsd-radio-trigger-element {
  margin-left: 10px;
}
  
@media screen and (max-width: 991.98px) {
  #container-question-jampp legend.dsd-radio-group-legend {
    margin-left: 10px;
  }
  
  #container-question-jampp .dsd-is-error .dsd-radio-group-list,
  .dsd-form-error {
    margin-left: 2px;
  }
  
  #container-question-jampp .dsd-radio-group-list {
    margin-left: 10px;
  }
}
  
#container-question-jampp ul {
  margin-top: var(--dsd-spacing-padding-xsmall) !important;
}

#container-question-jampp .libelle-erreur {
  color: var(--dsd-color-border-button-error)
}