.champs {
  font-size: 0.875rem;
}

.questionnaire-transition-enter {
  opacity: 0;
}
.questionnaire-transition-enter-active {
  opacity: 1;
  transition: opacity 200ms ease-in;
}