.jampp-contenu{
  overflow-y: auto;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
  margin-top: var(--dsd-spacing-padding-medium)
}

#nav-gabarit-transaction.gabarit-transaction .nav-gabarit-transaction-container {
  padding: 0 !important;
  display: flex;
}

#contenu-principal {
  flex-grow: 1;
}

@media (max-width: 992px) {
  .jampp-contenu {
    margin-top: var(--dsd-spacing-padding-small)
  }
}

/*
*DSD-GRID
*/
.dsd-custom-grid {
  height:100%;
  flex: 1 0 auto;
}

.dsd-custom-grid .dsd-grid {
  height: 100%;
}

.dsd-custom-grid > dsd-grid > dsd-grid-row > dsd-grid-col {
  padding: 0;
}

.dsd-custom-grid-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.dsd-custom-grid .dsd-custom-grid-left-column {
  background-color: var(--dsd-color-background-default);
}

main.dsd-custom-grid {
  z-index: 1;
}

/* MD+ */
@media screen and (min-width: 992px) {
  .dsd-custom-grid {
    > .dsd-grid > .dsd-row {
      margin: 0 !important;
    }
    .dsd-grid {
      margin-left: unset !important;
      margin-right: unset !important;
    }
  }
}

/* XS, SM */
@media (max-width: 991.99999px) {
  .dsd-custom-grid .dsd-grid {
    max-width: none;
    overflow: hidden;
  }
}
/***/

/* Pour bug scrolling iPad */
@media (max-width: 991.99999px) {
  .dsd-custom-grid .dsd-grid {
    max-width: none;
    overflow: hidden;
    overflow-y: auto;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  }
}

