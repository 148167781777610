#alerte-inactive, #alerte-erreur {
  margin-bottom: 18px;
}

#texte-numero-montreal, #texte-numero-ailleurs-quebec {
  display: inline-block;
}

#texte-montreal-environs, #texte-ailleurs-quebec{
  margin-bottom: 6px;
}

#texte-obtenir-aide{
  margin-bottom: 4px;
}

#dsdcontactbloc-container{
  margin-bottom: 20px;
}

hr{
  margin-top: -7.73px;
}

