body {
  background-color: #f4f4f4;
  line-height: 1.8 !important;
}

.App,
.Erreur {
  display: flex;
  flex-direction: column;
}

.App {
  position: relative;
}

html {
  scroll-behavior: smooth;
}

*:focus {
  outline: none;
}
