
  #titre-click-to-call{
    font-size: 1.125rem;
  }

  @media screen and (min-width: 639.98px) {
    .texte-demandez-appel  {
      margin-top: 8px;
    }
  
    .container-ctc
    {
      display: flex;
    }
  }