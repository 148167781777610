#btn-enregistrer,
#btn-continuer-sans-assurance,
#btn-precedent,
#btn-continuer {
  margin-right: 16px;
}

#boutons-container {
  margin-bottom: 36px;
}

@media screen and (max-width: 1199.98px) {
  #boutons-container {
    margin-bottom: 40px;
  }
}

@media screen and (max-width: 991.98px) {
  #btn-precedent {
    margin-bottom: 16px;
  }
}

@media screen and (max-width: 639.98px) {
  #btn-continuer,
  #btn-precedent,
  #btn-enregistrer,
  #btn-continuer-sans-assurance {
    margin-bottom: 16px;
    margin-right: 0px;
  }
}